import React, { useState } from "react";
import "./ChangePassword.css";
import { useFormik } from "formik";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { changePassword } from "../../../../components/validation/RegistrationValidation";
const initialValue = {
  old_password: "",
  password: "",
  password_confirmation: "",
};
const ChangePassword = () => {
  const [error, setError] = useState("");
  const { values, touched, errors, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: initialValue,
      validationSchema: changePassword,
      onSubmit: async (values, action) => {
        try {
          const token = "12|cRQfuQK9PqQXOkHqUjO26SswUHjSRZweWYwlp2IR90c08500";
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const password = await axios.post(
            "https://soulhousing-api.anchorstech.net/api/change-password",
            {
              old_password: values.old_password,
              password: values.password,
              password_confirmation: values.password_confirmation,
            },
            config
          );
          password && NotificationManager.success("Password changed");
        } catch (error) {
          console.log(error.message);
          NotificationManager.error("Something went wrong");
        }
      },
      validate: (values) => {
        const errors = {};

        // Check if password and confirm password match
        if (values.password !== values.password_confirmation) {
          errors.password_confirmation = "Passwords do not match";
        }

        return errors;
      },
    });

  return (
    <>
      
    <div className="password-forms right-sec">
      <h5>Change Password</h5>

      <div className="row mt-4 g-3">
        <div className="col-xl-6">
          <div className="form-floating">
            <input
              type="password"
              name="old_password"
              className="form-control w-100"
              id="currentPassword"
              placeholder="Current Password"
              value={values.old_password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="currentPassword">Current Password</label>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-floating">
            <input
              type="password"
              name="password"
              className="form-control w-100"
              id="newPassword"
              placeholder="New Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="newPassword">New Password</label>
          </div>
          {errors.password && touched.password ? (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    ) : null}
        </div>
        <div className="col-xl-6">
          <div className="form-floating">
            <input
              type="password"
              name="password_confirmation"
              className={`form-control w-100 ${
                errors.password_confirmation && touched.password_confirmation
                  ? "is-invalid"
                  : ""
              }`}
              id="confirmNewPassword"
              placeholder="Confirm New Password"
              value={values.password_confirmation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="confirmNewPassword">Confirm New Password</label>
            {errors.password_confirmation && touched.password_confirmation && (
              <div className="invalid-feedback">
                {errors.password_confirmation}
              </div>
            )}
          </div>
        </div>
        <button type="button" className="btn btn-signin button" onClick={handleSubmit}>
          Save Password
        </button>
      </div>
    </div>
    <NotificationContainer/>
    </>
  );
};

export default ChangePassword;
