import React,{useState,useEffect} from 'react';
import '../PatientProfile.css'
import axios from 'axios';
function Medications({id}) {
  const [medications,setMedications] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMedications =async ()=>{
    try{
      setLoading(true);
      const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const medications = await axios.get(`https://soulhousing-api.anchorstech.net/api/get-medication/${id}`,config)
    setMedications(medications.data.data)
     

    }catch(error){
      console.log(error.message)
    }finally {
      setLoading(false);
    }
  }
  useEffect(()=>{
    getMedications()

  },[])
  console.log("medications",medications)
  return (
    <div>
      <div className="d-flex flex-column h-100 problem-container">
        <div className="medication-links d-flex align-items-center">
          <h5>eRX</h5>
          <div className="form-check ms-auto">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Patient Self-Administrators Medications
            </label>
          </div>
          <div className="btn-group me-3 ms-2 Medications-btn-add">
            <button type="button" className="btn btn-danger">Add</button>
            <button type="button" className="btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="#">Action</a></li>
              <li><a className="dropdown-item" href="#">Another action</a></li>
              <li><a className="dropdown-item" href="#">Something else here</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div>
          <div className="btn-group Medications-btn-add">
            <button type="button" className="btn btn-danger">Important Medications</button>
            <button type="button" className="btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="#">Action</a></li>
              <li><a className="dropdown-item" href="#">Another action</a></li>
              <li><a className="dropdown-item" href="#">Something else here</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div>
        </div>
        <div className="active-medications d-flex align-items-center">


<h6>Active Medications (14)</h6>

<div className="ms-auto me-3 medication-anchor">
  <a href="#">Print Medications</a>
</div>

<div class="input-group date " id="datetimepicker" data-target-input="nearest">
  <input type="date" className="form-control datetimepicker-input " data-target="#datetimepicker"
    placeholder="Search"/>
</div>
</div>
        <div className="medication-tables flex-fill overflow-auto">
      <div className="table-responsive">
        <table>
          <thead className="thead-medications">
            <tr>
              <th className="w-25">
                <div className="form-check ms-auto">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Prescriber
                  </label>
                </div>
              </th>
              <th>Dispense</th>
              <th>Date</th>
              <th>Refills</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {loading ? (
                   <div className="spinner-border text-primary" role="status" style={{ display: 'block',padding:"10px" }}>
                   <span className="visually-hidden">Loading...</span>
                 </div>
                  ) : (medications.map((medication,index)=>{
            return(
              <tr key={index}>
              <td>
                <div className="form-check ms-auto medication-view">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    {medication.favourite_medication}
                  </label>
                </div>
              </td>
              <td>{medication.dispense}</td>
              <td>{medication.prescribe_date}</td>
              <td>{medication.refills}</td>
              <td>{medication.status}</td>
            </tr>
          )})
        )}
            
            {/* Repeat the above <tr> structure for other medication entries */}
          </tbody>
        </table>
      </div>
      <div className="medication-buttons mt-5 ms-2m ">
        <button type="button" className="btn btn-primary" style={{paddingLeft:"20px"}}>Share via eFex</button>
        <button type="button" className="btn btn-primary">Make Inactive</button>
        <button type="button" className="btn btn-primary">Recorder</button>
      </div>
      <div className="medical-history mt-5 d-flex align-items-center">
        <h6>Patient’s Medication History</h6>
        <div className="input-group date  .medical-history-btn ms-auto " id="datetimepicker" data-target-input="nearest">
          <input type="date" className="form-control datetimepicker-input " data-target="#datetimepicker" placeholder="Search" />
        </div>
      </div>
      <div className="medical-history mt-3 d-flex align-items-center">
        <h6>Patient’s Medication History</h6>
        <div className="input-group date  .medical-history-btn ms-auto " id="datetimepicker" data-target-input="nearest">
          <input type="date" className="form-control datetimepicker-input " data-target="#datetimepicker" placeholder="Search" />
        </div>
      </div>
    </div>
      </div>
    </div>
  );
}

export default Medications;
