import React, {useState} from 'react';
import { useLocation } from "react-router-dom";
import './PatientProfile.css'
import Profile from '../../../assets/images/user.png'
import Summary from './summary/Summary';
import Tabs from './demographics/Tabs';
import Problems from './problem/Problems';
import Medications from './medication/Medications';
import '@fortawesome/fontawesome-free/css/all.min.css';
function PatientProfile() {
  var patient = useLocation();
  var patientDetails = patient.state.patients;
  console.log("doctor Details", patientDetails);
    const [activeTab, setActiveTab] = useState('summary');
    const [patientDetail,setPatientDetail] = useState({
      first_name: patientDetails.first_name,
      middle_name: patientDetails.middle_name,
      last_name: patientDetails.last_name,
      nick_name: patientDetails.nick_name,
      suffix: patientDetails.suffix,
      ssn: patientDetails.ssn,
      gender: patientDetails.gender,
      date_of_birth: patientDetails.date_of_birth,
      general_identity: patientDetails.general_identity,
      other: patientDetails.other,
      location: patientDetails.location,
      pharmacy: patientDetails.pharmacy,
      address_1: patientDetails.address_1,
      address_2: patientDetails.address_2,
      city: patientDetails.city,
      state: patientDetails.state,
      email: patientDetails.email,
      phone_no: patientDetails.phone_no,
      zip_code: patientDetails.zip_code,
      country: patientDetails.country,
      status: patientDetails.status,
    })
  return (
    
    <>
    <div className="active d-flex fade h-100 m-1 overflow-hidden show " >
      {/* left section start */}
      <div className="d-flex flex-column h-100 m-1 overflow-hidden">
        <div className="d-flex flex-fill overflow-hidden">
          <div className="main-left problem-container ">
            <div className="left-nav ">
              <div className="patient-data d-flex">
                <div className="user-img">
                  <img src={Profile} alt="" />
                </div>
                <div className="user-detail ms-5">
                  <h5>{patientDetails.first_name + " " + patientDetails.last_name}</h5>
                  <span><a href="">Active</a></span>
                  <p>{patientDetails.gender}</p>
                  <ul className="d-flex user-icon">
                    <li><a href=""><i className="fas fa-home"></i></a></li>
                    <li><a href=""><i className="fas fa-cash-register"></i></a></li>
                    <li><a href=""><i className="fas fa-phone"></i></a></li>
                    <li><a href=""><i className="fas fa-envelope-open-text"></i></a></li>
                  </ul>
                </div>
              </div>

              <div className="user-bio mt-2  ">
                <ul className="user-bio-links">
                  <li><a href="#">Self-Administers Medications:<span>No</span></a></li>
                  <li><a href="#">Admission Status:&nbsp;<span>Long Term </span></a></li>
                  <li><a href="#">Address:&nbsp;<span>{patientDetails.address_1} </span></a></li>
                  <li><a href="#">Mobile:&nbsp;<span>{patientDetails.phone_no} </span></a></li>
                  <li><a href="#">DOB:&nbsp;<span>{patientDetails.date_of_birth}</span></a></li>
                  <li><a href="#">Gender:&nbsp;<span>{patientDetails.gender}</span></a></li>
                  <li><a href="#">Email:&nbsp;<span>{patientDetails.email}</span></a></li>
                  
                </ul>
              </div>
              <div className="accordion " id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Allergies
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Dust,Aspirin
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Patient
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Dust,Aspirin
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Clinical
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Dust,Aspirin
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-fill overflow-hidden right-sec">
      <div className="right-linkstabs d-flex align-items-center">
        {/* Nav tabs */}
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
          <button
                  className={`nav-link ${activeTab === 'summary' ? 'active' : ''}`}
                  onClick={() => setActiveTab('summary')} // Set active tab to 'summary' on click
                  id="summary-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#summary"
                  type="button"
                  role="tab"
                  aria-controls="summary"
                  aria-selected={activeTab === 'summary'}
                >
                  Summary
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'Demographics' ? 'active' : ''}`}
                  onClick={() => setActiveTab('Demographics')} // Set active tab to 'Demographics' on click
                  id="Demographics-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Demographics"
                  type="button"
                  role="tab"
                  aria-controls="Demographics"
                  aria-selected={activeTab === 'Demographics'}
                >
                  Demographics
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'Problems' ? 'active' : ''}`}
                  onClick={() => setActiveTab('Problems')} // Set active tab to 'Demographics' on click
                  id="Problems-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Problems"
                  type="button"
                  role="tab"
                  aria-controls="Problems"
                  aria-selected={activeTab === 'Problems'}
                >
                  Problems
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'Medications' ? 'active' : ''}`}
                  onClick={() => setActiveTab('Medications')} // Set active tab to 'Demographics' on click
                  id="Medications-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Medications"
                  type="button"
                  role="tab"
                  aria-controls="Medications"
                  aria-selected={activeTab === 'Medications'}
                >
                 Medications
                </button>
              </li>
          {/* <li className="nav-item" role="presentation">
            <button className="nav-link" id="Problems-tab" data-bs-toggle="tab" data-bs-target="#Problems" type="button" role="tab" aria-controls="Problems" aria-selected="false">Problems</button>
          </li> */}

    
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="Encounters-tab" data-bs-toggle="tab" data-bs-target="#Encounters" type="button" role="tab" aria-controls="Encounters" aria-selected="false">Encounters</button>
          </li>

          <li className="nav-item" role="presentation">
            <button className="nav-link " id="Notes-tab" data-bs-toggle="tab" data-bs-target="#Notes" type="button" role="tab" aria-controls="Notes" aria-selected="true">Notes</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="Documents-tab" data-bs-toggle="tab" data-bs-target="#Documents" type="button" role="tab" aria-controls="Documents" aria-selected="false">Documents</button>
          </li>
        </ul>
      </div>
    {activeTab === 'summary' && <Summary id={patientDetails.id}/>}
    {activeTab === 'Demographics' && <Tabs patientDetails={patientDetails}/>}
    {activeTab === 'Problems' && <Problems id={patientDetails.id}/>}
    {activeTab === 'Medications' && <Medications id={patientDetails.id}/>}
    </div>
    </div>
    {/* Conditionally render Summary component based on active tab */}
    </>
  );
}

export default PatientProfile;
