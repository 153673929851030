import React,{useEffect, useState} from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValue = {
  type: "",
  title: "",
  first_name: "",
  middle_name: "",
  date_of_birth: "",
  relationship: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  country: "",
  home_phon: "",
  work_phone: "",
  mobile_number: "",
  fax: "",
  method_of_contact: "",
  support_contact: "",
  from_date: "",
  to_date: "",
  status: "",
  indefinitely: "",
  power_of_attorney: "",
  from_date2: "",
  to_date2: "",
  status2: "",
  indefinitely2: "",
  power_of_attorney2: "",
  from_date3: "",
  to_date3: "",
  status3: "",
  indefinitely3: "",
};
const Contact = ({id}) => {
  const [ contactDetail,setContectDetail] = useState([])
  const [loading, setLoading] = useState(false);
  const { values, errors, handleChange, handleBlur, handleSubmit, touched } =
    useFormik({
      initialValues: initialValue,
      onSubmit: async (values, action) => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          const res = await axios.post(
            "https://soulhousing-api.anchorstech.net/api/add-contact",
            {
              patient_id:id,
              type: values.type,
              title: values.title,
              first_name: values.first_name,
              middle_name: values.middle_name,
              date_of_birth: values.date_of_birth,
              relationship: values.relationship,
              email: values.email,
              address: values.address,
              city: values.city,
              state: values.state,
              zip_code: values.zip_code,
              country: values.country,
              home_phon: values.home_phon,
              work_phone: values.work_phone,
              mobile_number: values.mobile_number,
              fax: values.fax,
              method_of_contact: values.method_of_contact,
              support_contact: values.support_contact,
              from_date: values.from_date,
              to_date: values.to_date,
              status: values.status,
              indefinitely: values.indefinitely,
              power_of_attorney: values.power_of_attorney,
              from_date2: values.from_date2,
              to_date2: values.to_date2,
              status2: values.status2,
              indefinitely2: values.indefinitely2,
              power_of_attorney2: values.power_of_attorney2,
              from_date3: values.from_date3,
              to_date3: values.to_date3,
              status3: values.status3,
              indefinitely3: values.indefinitely3,
            },
            config
          );
          if (res.data.status === "success") {
            toast.success(res.message);
          }
        } catch (error) {
          console.log(error.message);
          toast.error(error.message);
        }
        action.resetForm()
      },
    });
    
    const getContactDetail= async()=>{
      try{
        setLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const contact = await axios.get(`https://soulhousing-api.anchorstech.net/api/get-contact/${id}`,config)
        setContectDetail(contact)
         


      }catch(error){
        console.log(error.message)
      }finally {
        setLoading(false);
      }
    }
    useEffect(()=>{
      getContactDetail()
    },[])
    console.log("getContact",contactDetail)
  return (
    <div className="contact-bg-sec side-container">
      <div className="row">
        <div className="col-3">
          <div className="content-heading h-100">
            <div className="p-2 bg-contact">
              <h6>Contact</h6>
            </div>
            <div className="border-bottom"></div>
            {loading ? (
                   <div className="spinner-border text-primary" role="status" style={{ display: 'block',padding:"10px" }}>
                   <span className="visually-hidden">Loading...</span>
                 </div>
                  ) : (contactDetail.data&&contactDetail.data.data.map((contact,index)=>{
              {console.log("data",contact)}
              
              return (
              <>
                <ul key={index} style={{padding:"5px"}}>
                  <li style={{display:'flex',padding:"5px"}}><h6>Name:&nbsp; </h6> {contact.first_name +" " + contact.last_name}</li>
                  <li style={{display:'flex',padding:"0px 5px 0px 5px"}}><h6>Relationship:&nbsp; </h6> {contact.relationship}</li>
                  <li style={{display:'flex',padding:"0px 5px 0px 5px"}}><h6>Number:&nbsp; </h6> {contact.mobile_number}</li>
                  <li style={{display:'flex',padding:"0px 5px 0px 5px"}}><h6>Email:&nbsp; </h6> {contact.email}</li>
                </ul>
                <hr></hr>
                </>
             )})
            )}
           
          </div>
        </div>
        <div className="col-9">
          <div className="content-heading">
            <div className="p-2 bg-contact">
              <h6>
                <a href="#">Contact Us</a>
              </h6>
            </div>
            <div className="border-bottom"></div>
            <div className="contact-feilds h-100 mt-3">
              <div className="row g-3 mb-3">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="type"
                      className="form-control"
                      id="type"
                      placeholder="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="type">Select Type</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      id="title"
                      placeholder="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="title">Select Title</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      id="first_name"
                      placeholder="first name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="first_name">First Name</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="middle_name"
                      className="form-control"
                      id="middle_name"
                      placeholder="middle name"
                      value={values.middle_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="middle_name">Middle Name</label>
                  </div>
                </div>

                {/* Add more form fields here */}
              </div>
              <div className="row g-3 mb-3">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      name="date_of_birth"
                      className="form-control"
                      id="date_of_birth"
                      placeholder="date of birth"
                      value={values.date_of_birth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="date_of_birth">Date of Birth</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="relationship"
                      className="form-control"
                      id="relationship"
                      placeholder="relationship"
                      value={values.relationship}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="relationship">RelationShip</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      id="address"
                      placeholder="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="address">Address</label>
                  </div>
                </div>

                {/* Add more form fields here */}
              </div>
              <div className="row g-3 mb-3">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      id="city"
                      placeholder="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="city">City</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      id="state"
                      placeholder="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="state">State</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="zip_code"
                      className="form-control"
                      id="zip_code"
                      placeholder="zip code"
                      value={values.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="zip_code">Zip Code</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      id="country"
                      placeholder="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="country">Country</label>
                  </div>
                </div>

                {/* Add more form fields here */}
              </div>
              <div className="row g-3 mb-3">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      name="home_phon"
                      className="form-control"
                      id="home_phon"
                      placeholder="home phone"
                      value={values.home_phon}
                        onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="home_phon">Home Phone</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      name="work_phone"
                      className="form-control"
                      id="work_phone"
                      placeholder="work phone"
                      value={values.work_phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="work_phone">Work Phone</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      name="mobile_number"
                      className="form-control"
                      id="mobile_number"
                      placeholder="mobile number"
                      value={values.mobile_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="mobile_number">Mobile Number</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="fax"
                      className="form-control"
                      id="fax"
                      placeholder="fax"
                      value={values.fax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="fax">Fax</label>
                  </div>
                </div>

                {/* Add more form fields here */}
              </div>
              <div className="row g-3 mb-3">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="method_of_contact"
                      className="form-control"
                      id="method_of_contact"
                      placeholder="method of contact"
                      value={values.method_of_contact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="method_of_contact">Method of contact</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 d-flex align-items-center">
                  <div className="form-check">
                    <input
                    
                      className="form-check-input"
                      type="checkbox"
                      name="support_contact"
                      placeholder="support contact"
                      id="support_contact"
                      value={values.support_contact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="support_contact"
                    >
                      Support Contact
                    </label>
                  </div>
                </div>
              </div>

              <div className="row g-3 mb-3 ">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      name="from_date"
                      className="form-control"
                      id="from_date"
                      placeholder="from date"
                      value={values.from_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="from_date">From</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      name="to_date"
                      className="form-control"
                      id="to_date"
                      placeholder="to date"
                      value={values.to_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="to_date">To</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      id="state"
                      placeholder="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="state">Status</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="support_contact"
                      id="support_contact"
                      value={values.support_contact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="support_contact"
                    >
                      Indefinitely
                    </label>
                  </div>
                </div>

                {/* Add more form fields here */}
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="power_of_attorney"
                  id="power_of_attorney"
                  value={values.power_of_attorney}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label className="form-check-label" htmlFor="power_of_attorney">
                  POA (Power of Attorney)
                </label>
              </div>
              <div className="row g-3 mb-3 mt-2">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      name="from_date2"
                      className="form-control"
                      id="from_date2"
                      placeholder="from date "
                      value={values.from_date2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="from_date2">From</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      name="to_date2"
                      className="form-control"
                      id="to_date2"
                      placeholder="to date"
                      value={values.to_date2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="to_date2">To</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="status2"
                      className="form-control"
                      id="status2"
                      placeholder="status"
                      value={values.status2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="status2">Status</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="indefinitely"
                      id="indefinitely"
                      value={values.indefinitely}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="indefinitely"
                    >
                      Indefinitely
                    </label>
                  </div>
                </div>

                {/* Add more form fields here */}
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="to_date"
                  id="supportContact"
                
                />
                <label className="form-check-label" htmlFor="supportContact">
                  Healthcare Proxy
                </label>
              </div>
              <div className="row g-3 mb-3 mt-2">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      name="from_date3"
                      className="form-control"
                      id="from_date3"
                      placeholder="from date"
                      value={values.from_date3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="from_date3">From</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      name="to_date3"
                      className="form-control"
                      id="to_date3"
                      placeholder="to date"
                      value={values.to_date3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="to_date3">To</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="status3"
                      className="form-control"
                      id="status3"
                      placeholder="status"
                      value={values.status3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="status3">Status</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="indefinitely3"
                      
                      id="indefinitely3"
                      value={values.indefinitely3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="indefinitely3"
                    >
                      Indefinitely
                    </label>
                  </div>
                </div>

                {/* Add more form fields here */}
              </div>

              <div className="d-flex">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                  Save
                </button>
                <button type="button" className="btn btn-success ms-3">
                  Reset
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
