import React,{useState,useEffect} from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";

const initialValue = {
  diagnosis: "",
  cd_description: "",
  select_1: "",
  select_2: "",
  select_3: "",
  select_4: "",
  select_5: "",
  comments: "",
  icd10: "",
  snowed: "",
  status: "",
};
const Problems = ({id}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values, errors, handleChange, handleBlur, handleSubmit, touched } =
    useFormik({
      initialValues: initialValue,
      onSubmit: async (values, action) => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          const response = await axios.post(
            `https://soulhousing-api.anchorstech.net/api/add-problem`,
            {
              patient_id:id,
              diagnosis: values.diagnosis,
              cd_description: values.cd_description,
              select_1: values.select_1,
              select_2: values.select_2,
              select_3: values.select_3,
              select_4: values.select_4,
              select_5: values.select_5,
              comments: values.comments,
              icd10: values.icd10,
              snowed: values.snowed,
              status: values.status,
            },
            config
          );
          console.log(response);
        } catch (error) {
          console.log(error.message);
        }
        action.resetForm()
      },
      
    });

    const getProblems =async ()=>{
      try{
        setLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const response =await axios.get(`https://soulhousing-api.anchorstech.net/api/get-problem/${id}`,config)
        setData(response.data.data)
        
        
      }catch(error){
        console.log(error.message)
      }finally {
        setLoading(false);
      }
    } 

    useEffect(()=>{
      getProblems()

    },[])
    console.log("res",data) 
  return (
    <div className="problem-container">
      <div className="d-flex flex-column h-100">
        <div className="problem-links">
          <h3>Problems</h3>
        </div>
        <div className="active-bg-links flex-fill overflow-auto">
          <div className="row g-3">
            <div className="col-4">
              <div className="active-content">
                <h5>Problems Lists</h5>
                <div className="problem-anchors p-3">
                  <div className="problem-search-feild mb-4">
                    <div className="input-group input-search rounded ">
                      <input
                        type="search"
                        className="form-control rounded"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                      />
                    </div>
                  </div>
                  {loading ? (
                   <div className="spinner-border text-primary" role="status" style={{ display: 'block',padding:"10px" }}>
                   <span className="visually-hidden">Loading...</span>
                 </div>
                  ) : (data.map((problem,index)=>{
                    return(
                      <>
                      <ul key={index} style={{padding:"5px"}}>
                        <li style={{display:'flex',padding:"5px"}}><h6>Diagnosis:&nbsp;</h6>{problem.diagnosis}</li>
                        <li style={{display:'flex',padding:"0px 5px 0px 5px"}}><h6>Description:&nbsp;</h6>{problem.cd_description}</li>
                        <li style={{display:'flex',padding:"0px 5px 0px 5px"}}><h6>Status:&nbsp;</h6>{problem.status}</li>
                        <li style={{display:'flex',padding:"0px 5px 0px 5px"}}><h6>Comments:&nbsp;</h6>{problem.comments}</li>
                      </ul>
                      <hr></hr>
                      </>
                 
                    )})
                  )}
                 
                 
                  {/* Additional list items go here */}
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="problem-right-bg">
                <div className="d-flex  border-bottom mb-3  problems-bg-add">
                  <ul>
                    <li>
                      <h5>Add Problems</h5>
                    </li>
                  </ul>
                  <div className="add-problem-link ms-auto">
                    <a href="#">Add via speech</a>
                  </div>
                </div>
                <div className="p-3">
                  <div className="d-flex align-items-center mb-3">
                    <form className="form-diagnosis">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="diagnosis"
                          placeholder="diagnosis"
                          value={values.diagnosis}
                          name="diagnosis"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="diagnosis">Diagnosis *</label>
                      </div>
                    </form>
                    <div className="ms-3 diagnosis-btn">
                      <button type="submit" className="btn btn-secondary">
                        Snowed
                      </button>
                    </div>
                  </div>

                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control w-100"
                      id="cd_description"
                      placeholder="cd_description"
                      value={values.cd_description}
                      name="cd_description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label for="cd_description">Name</label>
                  </div>
                  <div className="row g-3 mb-3 mt-1">
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <select
                          className="form-select"
                          id="select_1"
                          name="select_1"
                          aria-label="Floating label select example"
                          value={values.select_1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label for="select_1">Works with selects</label>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <select
                          className="form-select"
                          id="select_2"
                          name="select_2"
                          aria-label="Floating label select example"
                          value={values.select_2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label for="select_2">Works with selects</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <select
                          class="form-select"
                          id="select_3"
                          name="select_3"
                          aria-label="Floating label select example"
                          value={values.select_3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option selected>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label for="select_3">Works with selects</label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-3 mt-1">
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <select
                          className="form-select"
                          id="select_4"
                          name="select_4"
                          aria-label="Floating label select example"
                          value={values.select_4}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label for="select_4">Works with selects</label>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <select
                          className="form-select"
                          id="select_5"
                          name="select_5"
                          aria-label="Floating label select example"
                          value={values.select_5}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option selected>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label for="select_5">Works with selects</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-floating mb-5">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="comments"
                      name="comments"
                      style={{ height: "100px" }}
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    <label for="comments">Comments</label>
                  </div>
                  <div className="d-flex">
                    <button type="button" className="btn btn-outline-dark me-3">
                      Reset
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                  {/* Additional forms and inputs go here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Problems;
