import React, { useEffect } from "react";
import "./Registration.css";
import { useFormik } from "formik";
import axios from "axios";
import { registrationValidation } from "../../../components/validation/RegistrationValidation";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const initialValue = {
  first_name: "",
  middle_name: "",
  last_name: "",
  nick_name: "",
  suffix: "",
  ssn: "",
  gender: "",
  date_of_birth: "",
  general_identity: "",
  other: "",
  location: "",
  pharmacy: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  email: "",
  phone_no: "",
  zip_code: "",
  country: "",
  status: "",
};
function RegistrationForm() {
   

  const { values, touched, handleChange, handleBlur, handleSubmit, errors ,setFieldValue} =
  useFormik({
    initialValues: initialValue,
    validationSchema: registrationValidation,
    onSubmit: async (values, action) => {
      try {
        // const userLocalStorage = JSON.parse(localStorage.getItem('persist:root'));
        // const userToken = userLocalStorage.user.currentUser.data.token;
        // console.log("User Token:", userToken);
        
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            
          },
          };
          const patient = await axios.post(
            "https://soulhousing-api.anchorstech.net/api/add-patient",
            {
              first_name: values.first_name,
              middle_name: values.middle_name,
              last_name: values.last_name,
              nick_name: values.nick_name,
              suffix: values.suffix,
              ssn: values.ssn,
              gender: values.gender,
              date_of_birth: values.date_of_birth,
              general_identity: values.general_identity,
              other: values.other,
              location: values.location,
              pharmacy: values.pharmacy,
              address_1: values.address_1,
              address_2: values.address_2,
              city: values.city,
              state: values.state,
              email:values.email,
              phone_no:values.phone_no,
              zip_code: values.zip_code,
              country: values.country,
              status: values.status,
            },
            config
          );
          patient &&
            NotificationManager.success("Patient Registered Successfully");
        } catch (error) {
          console.log(error.message);
          NotificationManager.error("Something went wrong");
        }
        action.resetForm();
      },
    });
    useEffect(() => {
        const phoneInput = document.getElementById('phone_no');
        const formatPhoneNumber = (e) => {
          var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          const formattedPhoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
          // Update the state with the formatted phone number only if the input is not empty
          if (e.target.value.trim() !== '') {
            setFieldValue('phone_no', formattedPhoneNumber);
          }
        };
        phoneInput.addEventListener('blur', formatPhoneNumber);
        return () => {
          phoneInput.removeEventListener('blur', formatPhoneNumber);
        };
      }, [setFieldValue]);
  return (
    <div className="main-body">
      <NotificationContainer />
      <div className="main-patientinfo">
        <h1>Demographics</h1>
        <div className="patient-info-bg">
          <div className="row">
            <div className="col-6">
              <div className="personal-info">
                <h3>Patient Information</h3>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        className="form-select w-100"
                        id="floatingSelectGrid"
                      >
                        <option value="">select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">Title</label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="first name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">First Name</label>
                    </div>
                    {errors.first_name && touched.first_name ? (
                      <p style={{ color: "red" }}>{errors.first_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="middle name"
                        name="middle_name"
                        value={values.middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">Middle Name</label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="last name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">Last Name</label>
                    </div>
                    {errors.last_name && touched.last_name ? (
                      <p style={{ color: "red" }}>{errors.last_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="nick name"
                        name="nick_name"
                        value={values.nick_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">Nick Name</label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-two w-100 ">
                      <form>
                        <button className="btn-previous">
                          <a href="#">Add Previous Name </a>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="number"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="suffix"
                        name="suffix"
                        value={values.suffix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">Suffix</label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="ssn"
                        name="ssn"
                        value={values.ssn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">SSN</label>
                    </div>
                    {errors.ssn && touched.ssn ? (
                      <p style={{ color: "red" }}>{errors.ssn}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        className="form-select w-100"
                        id="floatingSelectGrid"
                      >
                        <option value="">select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">Gender</label>
                    </div>
                    {errors.gender && touched.gender ? (
                      <p style={{ color: "red" }}>{errors.gender}</p>
                    ) : null}
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="date"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="date of birth"
                        name="date_of_birth"
                        value={values.date_of_birth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">DOB</label>
                    </div>
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="general_identity"
                        onChange={handleChange}
                        value={values.general_identity}
                        className="form-select w-100"
                        id="floatingSelectGrid"
                      >
                        <option value="">select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">
                        General Identity
                      </label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="other"
                        onChange={handleChange}
                        value={values.other}
                        className="form-select w-100"
                        id="floatingSelectGrid"
                      >
                        <option value="">select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">Other</label>
                    </div>
                  </div>
                </div>
                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="suffix"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">Email</label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="tel"
                        className="form-control w-100"
                        id="phone_no"
                        placeholder="(555) 555-5555"
                        name="phone_no"
                        value={values.phone_no}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">Phone No:</label>
                    </div>
                    {errors.phone_no && touched.phone_no ? (
                      <p style={{ color: "red" }}>{errors.phone_no}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="personal-info">
                <h3>Contact Information</h3>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="location"
                        onChange={handleChange}
                        value={values.location}
                        className="form-select w-100"
                        id="floatingSelectGrid"
                      >
                        <option value="">select</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Canada">Canada</option>
                        <option value="USA">USA</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">
                        Default Location
                      </label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="pharmacy"
                        onChange={handleChange}
                        value={values.pharmacy}
                        className="form-select w-100"
                        id="floatingSelectGrid"
                      >
                        <option value="">No Pharmacy found</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">
                        Pharmacy (Select)
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="address"
                        name="address_1"
                        value={values.address_1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">
                        {" "}
                        Address (Line 1)
                      </label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="address"
                        name="address_2"
                        value={values.address_2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">
                        Address (Line 2)
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="city"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">City (1)</label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="state"
                        onChange={handleChange}
                        value={values.state}
                        className="form-select w-100 "
                        id="floatingSelectGrid"
                      >
                        <option value="">select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">State</label>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="floatingInputGrid"
                        placeholder="zip_code"
                        name="zip_code"
                        value={values.zip_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="floatingInputGrid">ZIP Code</label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="country"
                        onChange={handleChange}
                        value={values.country}
                        className="form-select w-100 "
                        id="floatingSelectGrid"
                      >
                        <option value="">select</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Canada">Canada</option>
                        <option value="USA">USA</option>
                      </select>
                      <label htmlFor="floatingSelectGrid">Country</label>
                    </div>
                  </div>
                </div>
                <div className="btn-signin shadow-sm">
                  <button
                    type="button"
                    className="btn btn-signin"
                    onClick={handleSubmit}
                  >
                    Add Patient
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
