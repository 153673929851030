import React,{useState} from 'react';

import { useSelector, useDispatch } from "react-redux";
import { loginStart,loginSuccess,loginFailure } from '../../components/redux/user/UserSlice';
import './LoginPage.css'
import Logo from '../../assets/images/logo (3).png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link,useNavigate } from 'react-router-dom';
function LoginForm() {
  const [loading, error] = useSelector((state) => [state.user.loading, state.user.error]);

  const dispatch = useDispatch()
  const [formData,setFormData] = useState({})
    let navigate = useNavigate();
    const handleChange=((e)=>{
      setFormData({
        ...formData,
      [e.target.id]:e.target.value,
      })

    })
const handleSubmit = async(e)=>{
  e.preventDefault();
  try{
    dispatch(loginStart())
    const res =await fetch("https://soulhousing-api.anchorstech.net/api/login",{
      method:"POST",
      headers:{
        "Content-type":"application/json"
      },
      body: JSON.stringify(formData)
    })
    const data = await res.json()
    if (data.success == false){
      dispatch(loginFailure(data.message));
      return
    }
    const token = data.data.token;
    // Storing the token in local storage
    localStorage.setItem('token', token);
    dispatch(loginSuccess(data))
    navigate('/patient_list')


  }catch(error){
    dispatch(loginFailure(error))

  }

}
    // const login = () => {
    //     navigate('/patient_list');
    // }
  return (
    <div className="login-body">
      <div className="main-login d-flex align-items-center justify-content-center h-100">
        <div className="login-page-bg shadow-lg">
          <div className="brand-logo text-center">
            <img src={Logo} alt="Soul Housing Logo" />
          </div>
          <form onSubmit={handleSubmit}>
          <div className="login-inputs d-flex align-items-center justify-content-center flex-column">
            <div className="input-group mb-3">
              <span className="input-group-text " style={{height:"3.5rem"}}><FontAwesomeIcon  icon={faUser} /></span>
              <div className="form-floating">
                <input type="text" className="form-control " id="email" placeholder="Username" onChange={handleChange}/>
                <label htmlFor="email">Username</label>
              </div>
            </div>
            <div className="input-group flex-nowrap ">
              <div className="input-group mb-3">
                <span className="input-group-text" style={{height:"3.5rem"}}><FontAwesomeIcon icon={faLock} /></span>
                <div className="form-floating">
                  <input type="password" className="form-control" id="password" placeholder="Password" onChange={handleChange}/>
                  <label htmlFor="password">Password</label>
                </div>
              </div>
            </div>
            <div className="btn-signin shadow-sm">
              <button type="submit" className="btn btn-signin" > {loading ? "loading.." : "sign in"}</button>
            </div>
          </div>
          </form>
          <div className="btn-forgot text-center">
            <button className="btn btn-primary" type="button">Forgot Password</button>
          </div>
          {error ? <p className="text-red-500">{error.message}</p> : null}
        </div>
      </div>
    </div>
  );

 
}

export default LoginForm;
