import React,{useState} from 'react';
import './ManageMap.css'
import RoomManagement from './Preview';
function ManageMap() {
  const [showRoomManagement, setShowRoomManagement] = useState(false);

  const handlePreviewClick = () => {
    setShowRoomManagement(true);
  };
  return (
    <div style={{width:'68rem'}} className='right-sec flex-fill '>
     {/* <div className="tab-pane fade" id="v-pills-FaciltyManagement" role="tabpanel" aria-labelledby="v-pills-settings-tab"> */}
      <div className="facilty-links ">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#ManageMap" type="button" role="tab" aria-controls="home" aria-selected="true">Manage Map</button>
          </li>
          <li className="nav-item" role="presentation">
          <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#Preview"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              onClick={handlePreviewClick}
            >
              Preview
            </button>
          </li>
        </ul>
      </div>
      <div className="tab-content" id="myTabContent">
        {/* ManageMap start */}
        <div className="tab-pane fade show active" id="ManageMap" role="tabpanel" aria-labelledby="home-tab">
          <div className="map-bg-sec">
            <div className="map-content">
              <div className="d-flex align-items-center mt-3">
                <h5>No. of Floors:</h5>
                <div className="form-floating mb-3 ms-3 w-50">
                  <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Enter No. of Floors</label>
                </div>
              </div>
              <div className="map-bg mt-3">
                <div className="d-flex align-items-center">
                  <h5>Floor No. 1</h5>
                  <div className="d-flex align-items-center ms-auto">
                    <h5>No. of Floors:</h5>
                    <div className="form-floating ms-3 w-100">
                      <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                      <label htmlFor="floatingInput">Enter No. of Floors</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-border">
                <div className="row">
                  <div className="col-6">
                    <h5 className="mt-3 mb-3">Room No. 1</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                    <h5 className="mt-3 mb-3">Room No. 3</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating mb-3 ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <h5 className="mt-3 mb-3">Room No. 2</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating mb-3 ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                    <h5 className="mt-3 mb-3">Room No. 4</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating mb-3 ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-bg mt-3">
                <div className="d-flex align-items-center">
                  <h5>Floor No. 1</h5>
                  <div className="d-flex align-items-center ms-auto">
                    <h5>No. of Floors:</h5>
                    <div className="form-floating ms-3 w-100">
                      <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                      <label htmlFor="floatingInput">Enter No. of Floors</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-border">
                <div className="row">
                  <div className="col-6">
                    <h5 className="mt-3 mb-3">Room No. 1</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                    <h5 className="mt-3 mb-3">Room No. 3</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating mb-3 ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <h5 className="mt-3 mb-3">Room No. 2</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating mb-3 ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                    <h5 className="mt-3 mb-3">Room No. 4</h5>
                    <div className="d-flex align-items-center ms-auto">
                      <h5>No. of Bed</h5>
                      <div className="form-floating mb-3 ms-3 w-75">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Enter No. of Floors</label>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        </div>
      </div>
     {/* </div> */}
     {showRoomManagement && <RoomManagement />}
    </div>
  );
}

export default ManageMap;
