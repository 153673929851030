import * as Yup from 'yup'

const registrationValidation=Yup.object({
    first_name:Yup.string().min(2).required("Please enter your First Name "),
    last_name:Yup.string().min(2).required("Please enter your Last Name"),
    ssn:Yup.string().required("Please enter SSN"),
    gender:Yup.string().required("Please enter your Gender"),
    phone_no:Yup.string().min(5).required("Please enter your Phone No")
})

const changePassword=Yup.object({
    password:Yup.string().min(8).required("Please enter password")
})

export {registrationValidation,changePassword}