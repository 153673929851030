import React from 'react';
import './Security.css'
const Security = () => {
  return (
    <div className="right-sec flex-fill">
      <div className="security-content h-100">
        <h5>Security</h5>
        <h6>Two factor authentication is not enabled yet.</h6>
        <p>Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.</p>
        <h5>Two Factor Authentication status</h5>
      </div>
    </div>
  );
};

export default Security;
