import React, { useEffect, useState } from "react";
import "../PatientProfile.css";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Demographics = ({ details }) => {
  console.log("details", details);
  const [disableFields, setDisableFields] = useState(true);
  const [patientDetail, setPatientDetail] = useState({
    id:details.id,
    first_name: details.first_name,
    middle_name: details.middle_name,
    last_name: details.last_name,
    nick_name: details.nick_name,
    suffix: details.suffix,
    ssn: details.ssn,
    gender: details.gender,
    date_of_birth: details.date_of_birth,
    general_identity: details.general_identity,
    other: details.other,
    location: details.location,
    pharmacy: details.pharmacy,
    address_1: details.address_1,
    address_2: details.address_2,
    city: details.city,
    state: details.state,
    email: details.email,
    phone_no: details.phone_no,
    zip_code: details.zip_code,
    country: details.country,
    status: details.status,
  });
  const handleInput = (e) => {
    let name, value;

    console.log(e);
    name = e.target.id;
    value = e.target.value;
    setPatientDetail({ ...patientDetail, [name]: value });
  };
  const handleSubmit = async(e)=>{
    e.preventDefault();
    try{
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          
        },
        };
    
      const {data} = await axios.post(`https://soulhousing-api.anchorstech.net/api/update-patient`,patientDetail,config);
      if(data.status === 200){
        toast.success(data.message);
      }else{
        toast.error(data.message);
      }

    }catch(error){
      console.log("error",error.message)
    }
  }
  return (
    <div className="d-flex flex-column flex-fill h-100 overflow-auto side-container">
      <div className="tab-content" id="nav-tabContent">
        {/* demographics tab start */}
        <div
          className="tab-pane fade show active"
          id="nav-Demographics"
          role="tabpanel"
          aria-labelledby="nav-Demographics-tab"
          tabIndex="0"
        >
          <div className="demo-main-patient">
            <div className="patient-info-bg ">
              <div className="row">
                <div className="col-lg-6">
                  <div className="personal-info">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>Patient Information</h3>

                      <button
                        className="btn btn-primary "
                        onClick={() => {
                          setDisableFields(false);
                        }}
                      >
                        {" "}
                        Edit
                      </button>
                    </div>

                    <div className="row g-3 mb-5">
                      <div className="col-md">
                        <div className="form-floating">
                          <select className="form-select w-100" id="title"  onChange={handleInput} disabled={disableFields}>
                            <option value="">select</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                          </select>
                          <label htmlFor="title">Title</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="first_name"
                            placeholder="first name"
                            name="first_name"
                            value={patientDetail.first_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="first_name">First Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-5">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="middle_name"
                            placeholder="middle name"
                            name="middle_name"
                            value={patientDetail.middle_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="middle_name">Middle Name</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="last_name"
                            placeholder="last name"
                            name="last_name"
                            value={patientDetail.last_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="last_name">Last Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-5">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="nick_name"
                            placeholder="nick name"
                            name="nick_name"
                            value={patientDetail.nick_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="nick_name">Nick Name</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-two w-100 ">
                          <form>
                            <button className="btn-previous">
                              <a href="#">Add Previous Name </a>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-5">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="number"
                            className="form-control w-100"
                            id="suffix"
                            placeholder="suffix"
                            name="suffix"
                            value={patientDetail.suffix}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="suffix">Suffix</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="ssn"
                            placeholder="ssn"
                            name="ssn"
                            value={patientDetail.ssn}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="ssn">SSN</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-5">
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="gender"
                            value={patientDetail.gender}
                            onChange={handleInput}
                            className="form-select w-100"
                            id="gender"
                            disabled={disableFields}
                          >
                            <option value="" selected>{patientDetail.gender}</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          <label htmlFor="gender">Gender</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="date"
                            className="form-control w-100"
                            id="date_of_birth"
                            placeholder="date of birth"
                            name="date_of_birth"
                            value={moment(patientDetail.date_of_birth).utc().format("YYYY-MM-DD")}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="date_of_birth">DOB</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-5">
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="general_identity"
                            value={patientDetail.general_identity}
                            onChange={handleInput}
                            className="form-select w-100"
                            id="general_identity"
                            disabled={disableFields}
                          >
                            <option value="" selected>{patientDetail.general_identity}</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label htmlFor="general_identity">
                            General Identity
                          </label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="other"
                            value={patientDetail.other}
                            onChange={handleInput}
                            className="form-select w-100"
                            id="other"
                            disabled={disableFields}
                          >
                            <option value="" selected>{patientDetail.other}</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label htmlFor="other">Other</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-5 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control w-100"
                            id="email"
                            placeholder="email"
                            name="email"
                            value={patientDetail.email}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="email">Email</label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="tel"
                            className="form-control w-100"
                            id="phone_no"
                            placeholder="(555) 555-5555"
                            name="phone_no"
                            value={patientDetail.phone_no}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="phone_no">Phone No:</label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <button type="button" className="btn btn-primary ">
                        Save
                      </button>
                      <button type="button" className="btn btn-success ms-3">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                {/* Second column */}
                <div className="col-lg-6">
                  <div className="personal-info">
                    <h3>Contact Information</h3>
                    <div className="row g-3 mb-5">
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="location"
                            value={patientDetail.location}
                            onChange={handleInput}
                            className="form-select w-100"
                            id="location"
                            disabled={disableFields}
                          >
                            <option value="" selected>{patientDetail.location}</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Canada">Canada</option>
                            <option value="USA">USA</option>
                          </select>
                          <label htmlFor="location">Default Location</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="pharmacy"
                            value={patientDetail.pharmacy}
                            onChange={handleInput}
                            className="form-select w-100"
                            id="pharmacy"
                            disabled={disableFields}
                          >
                            <option value="" selected>{patientDetail.pharmacy}</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label htmlFor="pharmacy">Pharmacy (Select)</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-5 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="address_1"
                            placeholder="address"
                            name="address_1"
                            value={patientDetail.address_1}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="address_1"> Address (Line 1)</label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="address_2"
                            placeholder="address"
                            name="address_2"
                            value={patientDetail.address_2}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="address_2">Address (Line 2)</label>
                        </div>
                      </div>
                    </div>

                    <div className="row g-3 mb-5 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="city"
                            placeholder="city"
                            name="city"
                            value={patientDetail.city}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="city">City (1)</label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="state"
                            value={patientDetail.state}
                            onChange={handleInput}
                            className="form-select w-100 "
                            id="state"
                            disabled={disableFields}
                          >
                            <option value="" selected>{patientDetail.state}</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label htmlFor="state">State</label>
                        </div>
                      </div>
                    </div>

                    <div className="row g-3 mb-5 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="zip_code"
                            placeholder="zip_code"
                            name="zip_code"
                            value={patientDetail.zip_code}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="zip_code">ZIP Code</label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="country"
                            value={patientDetail.country}
                            onChange={handleInput}
                            className="form-select w-100 "
                            id="country"
                            disabled={disableFields}
                          >
                            <option value="" selected>{patientDetail.country}</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Canada">Canada</option>
                            <option value="USA">USA</option>
                          </select>
                          <label htmlFor="country">Country</label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <button type="button" className="btn btn-primary " onClick={handleSubmit}>
                          Save
                        </button>
                        <button type="button" className="btn btn-success ms-3">
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Demographics;
