import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from './pages/login/LoginPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PatientList from './pages/patient/PatientList';
import PatientDetail from './pages/patient detail/detail/PatientDetail';
import RegistrationForm from './pages/patient/registration/Registration';


function App() {
  return (
    <div>
      <BrowserRouter>
        
        <Routes>
        <Route path="/" element={<LoginForm />} />
          <Route path='/patient_list' element={<PatientList/>}/>
          <Route path='/patient_detail' element={<PatientDetail/>} />
          <Route path='/registration_form' element={<RegistrationForm/>}/>
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;