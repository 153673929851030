import React from 'react';
// import './ManageMap.css'
function RoomManagement() {
  return (
    <div >
      <div className="preview-links">
        <ul className="nav nav-tabs mt-5" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active mb-3" id="home-tab" data-bs-toggle="tab" data-bs-target="#floor1-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Floor 1</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#floor2-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Floor 2</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#floor3-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Floor 3</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#floor4-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Floor 4</button>
          </li>
        </ul>
      </div>
      <div className="tab-content" id="myTabContent">
        {/* Floor 1 tab start */}
        <div className="tab-pane fade show active h-100 overflow-auto" id="floor1-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
          <div className="floor-1-bg">
            <div className="row">
              {/* Room 1 */}
              <div className="col-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseroomone" aria-expanded="false" aria-controls="collapseOne">
                        Room 1
                      </button>
                    </h2>
                    <div id="collapseroomone" className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{}}>
                      <div className="accordion-body">
                        Dust,Aspirin
                      </div>
                    </div>
                  </div>
                </div>
                {/* Bed 1 to Bed 8 */}
                {/* Repeat the structure for Bed 2 to Bed 8 */}
              </div>
              {/* Room 2 */}
              {/* Repeat the structure for Room 2 */}
            </div>
            {/* Room 3 and Room 4 */}
            {/* Repeat the structure for Room 3 and Room 4 */}
          </div>
        </div>
        {/* Floor 1 tab Close */}
        {/* Floor 2, Floor 3, and Floor 4 tabs */}
        <div className="tab-pane fade" id="floor2-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">...</div>
        <div className="tab-pane fade" id="floor3-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">...</div>
        <div className="tab-pane fade" id="floor4-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">...</div>
      </div>
    </div>
  );
}

export default RoomManagement;
