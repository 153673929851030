import React from "react";
import "./ChangePin.css";
import { useFormik } from "formik";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const initialValue = {
  password: "",
  pin: "",
  confirm_pin: "",
};
const ChangePin = () => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValue,
      onSubmit: async (values, action) => {
        try {
          const token = "13|vC3vyngrbhelhiUaBtxhnd7PyYw2cdRIN5ABrnLO01a8b5d4";
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const pin = await axios.post(
            "https://soulhousing-api.anchorstech.net/api/set-pin",
            {
              password: values.password,
              pin: values.pin,
              confirm_pin: values.confirm_pin,
            },
            config
          );
          pin && NotificationManager.success("Pin changed");
        } catch (error) {
          console.log(error.message);
          NotificationManager.error("Something went wrong");
        }
        action.resetForm();
      },
      validate: (values) => {
        const errors = {};

        // Check if password and confirm password match
        if (values.pin !== values.confirm_pin) {
          errors.confirm_pin = "Pin do not match";
        }

        return errors;
      },
    });
  return (
    <div className="password-forms right-sec">
      <h5>Change pin</h5>
      <div className="row mt-4 g-3">
        <div className="col-xl-4">
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="currentPassword"
              name="password"
              placeholder="name@example.com"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="currentPassword">Current Password</label>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="newPassword"
              placeholder="name@example.com"
              name="pin"
              value={values.pin}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="newPassword">New Password</label>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-floating">
            <input
              type="email"
              className={`form-control w-100 ${
                errors.confirm_pin && touched.confirm_pin
                  ? "is-invalid"
                  : ""
              }`}
              id="confirmNewPassword"
              placeholder="name@example.com"
              name="confirm_pin"
              value={values.confirm_pin}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="confirmNewPassword">Confirm New Password</label>
            {errors.confirm_pin && touched.confirm_pin && (
              <div className="invalid-feedback">
                {errors.confirm_pin}
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          className="btn btn-signin button"
          onClick={handleSubmit}
        >
          Save Password
        </button>
      </div>
    </div>
  );
};

export default ChangePin;
