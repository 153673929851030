import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/header/Header";
import user from "../../assets/images/user.png";
import vector1 from "../../assets/images/Vector (1).png";
import vector from "../../assets/images/Vector.png";
import "./PatientList.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPatient = async () => {
      try {
        setLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await axios.get(
          "https://soulhousing-api.anchorstech.net/api/get-patients",
          config
        );
        setPatients(response.data.data); // Accessing the 'data' property of the response
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }finally {
        setLoading(false);
      }
    };
    getPatient();
  }, []);

  if (loading) {
    return  <div className="spinner-border text-primary" role="status" style={{ display: 'block',marginLeft:"50%",marginTop:"20%",padding:'25px' }}>
    <span className="visually-hidden">Loading...</span>
  </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Header />
      <section className="patient-list">
        <div className="d-flex mb-3">
          <div className="patient-btn">
            <button type="button" className="btn btn-success">
              Patients list
            </button>
          </div>
          <div className="input-group input-search rounded ms-5 ms-auto">
            <input
              type="search"
              className="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
            <a href="#">
              <FontAwesomeIcon icon={faSearch} className="search-icon-list" />
            </a>
          </div>
        </div>
        <div className="patient-info">
          <div className="table-responsive">
            <table>
              <thead>
                <tr className="mb-2">
                  <th>
                    <img src={vector1} alt="" />
                  </th>
                  <th>Patient Name</th>
                  <th>Gender</th>
                  <th>Location/Room#</th>
                  <th>Date of Birth</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
                   <div className="spinner-border text-primary" role="status" style={{ display: 'block',padding:"10px" }}>
                   <span className="visually-hidden">Loading...</span>
                 </div>
                  ) : (patients.map((patient, index) => (
                  <tr key={index} className="mb-2">
                    <td>
                      <img src={vector} alt="" />
                    </td>
                    <td>
                      <img className="user-img" src={user} alt="User Image" />
                      <Link to="/patient_detail/" state={{patients:patient}}>
                        <span className="ms-5">{`${patient.first_name} ${patient.last_name}`}</span>
                      </Link>
                    </td>
                    <td>{patient.gender}</td>
                    <td>
                      <a href="#">{patient.location}</a>
                    </td>
                    <td>{patient.date_of_birth}</td>
                  </tr>
                ))
              )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PatientList;
