import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store,persistor } from './components/redux/Store';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
ReactDOM.createRoot(document.getElementById('root')).render(
  
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
  <App />
  </PersistGate>
  </Provider>

);
