import React from 'react';
import './Card.css'
// Card component for rendering individual cards
const Card = ({ title, items }) => {
  return (
    <div className="demographics-cards">
      <div className="basic-bg">
        <h5>{title}</h5>
      </div>
      <div className="basic-info">
        <ul>
          {items.map((item, index) => (
            <li key={index}><a href={item.link}><span>{item.label}</span>{item.value}</a></li>
          ))}
            {title === "Most Recent Encounter" && (
            <li>
              <button className="btn-encounters-links button" ><a href="">Signed</a></button>
              <button className="btn-encounters-links button"><a href="">View Last Signed Encounter</a></button>
              <button className="btn-encounters-links button"><a href="">View</a></button>
              <button className="btn-encounters-links button"><a href="">Follow-up Encounter</a></button>
              <button className="btn-encounters-links button"><a href="">No Encounter</a></button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
export default Card
