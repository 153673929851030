import React,{useState,useEffect}from 'react';
import '../PatientProfile.css'
import axios from 'axios';
const Insurance = ({id}) => {
    const [insuranceData,setInsuranceData] = useState([])
    const [loading, setLoading] = useState(false);
    const getInsuranceData=async()=>{
        try{
            setLoading(true);
            const config = {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              };
            const response = await axios.get(`https://soulhousing-api.anchorstech.net/api/get-insurance/${id}`,config)
            setInsuranceData(response.data)
            console.log(response.data)

        }catch(error){
            console.log(error.message)

        }finally {
            setLoading(false);
          }
    }
    useEffect(()=>{
        getInsuranceData()
    },[])
    console.log("data",insuranceData)
    return (
        <div  >
            <div className="insurance-heading  d-flex align-items-center ">
                <h5>Insurances</h5>
                <ul className="ms-auto">
                    <li><a href="#">Add new</a></li>
                </ul>
            </div>
            <div className="insurance-p side-container">
                <div className="d-flex align-items-center insurance-links">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Patient is on self pay
                        </label>
                    </div>
                    <div className="ms-auto">
                        <button type="button" className="btn btn-success">save</button>
                    </div>
                </div>
                <div className="insurance-data d-flex justify-content-center align-items-center">
                    <ul className="mt-auto">
                        <li><a href="#">No insurance data found!</a></li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-0">
                        <div className="insurance-search mt-4">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-magnifying-glass"></i></span>
                                <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-bottom text-black"></div>
                <div className="table-responsive insurance-table">
                    <table className="table">
                        <thead>
                            <tr className="mb-2">
                              
                                <th>Plan name</th>
                                <th>Relationship</th>
                                <th>Policy No #</th>
                                <th>Gender</th>
                                <th>Group No #</th>
                                <th>Medicare Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {loading ? (
                   <div className="spinner-border text-primary" role="status" style={{ display: 'block',padding:"10px" }}>
                   <span className="visually-hidden">Loading...</span>
                 </div>
                  ) : (insuranceData.data && insuranceData.data.map((insurance,index)=>{
                                return(
                                    <tr className="mb-2" key={index}>
                                    
                                    <td>{insurance.plan_name}</td>
                                    <td>{insurance.relationship}</td>
                                    <td>{insurance.policy_number}</td>
                                    <td>{insurance.gender}</td>
                                    <td>{insurance.group_number}</td>
                                    <td>{insurance.secondry_medicare_type}</td>
                                    <td>{insurance.status}</td>
                                </tr>
                             )})
                            )}
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Insurance;
