import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import axios from "axios";
const initialValue = {
  first_name: "",
  last_name: "",
  date_of_birth: "",
  gender: "",
};

const PatientModal = ({ show, handleClose, handleShow, registrationForm }) => {
  const [isPatientRegistered, setIsPatientRegistered] = useState(false);
const [message,setMessage] = useState('')
const [loading, setLoading] = useState(true);
  const handleSearch = () => {
    // Logic to check if patient is registered
    const isRegistered = true; // Replace with your actual logic to check registration
    setIsPatientRegistered(isRegistered);
  };
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
  
  } = useFormik({
    initialValues: initialValue,

    onSubmit: async (values, action) => {
      try {
        // const userLocalStorage = JSON.parse(localStorage.getItem('persist:root'));
        // const userToken = userLocalStorage.user.currentUser.data.token;
        // console.log("User Token:", userToken);
        setLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const patient = await axios.post(
          "https://soulhousing-api.anchorstech.net/api/check-patient-record",
          {
            first_name: values.first_name,
            last_name: values.last_name,
            gender: values.gender,
            date_of_birth: values.date_of_birth,
          },
          config
        );
        setMessage(patient.data.message)
        
      } catch (error) {
        console.log(error.message);
      }finally {
        setLoading(false);
      }
      action.resetForm();
    },
  });
  console.log("message",message)
  return (
    <>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="m-3">
            <div className="d-flex mb-4">
              <form className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  id="first_name"
                  placeholder="first name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="first_name">First Name</label>
              </form>

              <form className="form-floating ms-3">
                <input
                  type="text"
                  name="last_name"
                  className="form-control"
                  id="last_name"
                  placeholder="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="last_name">Last Name</label>
              </form>
            </div>
            <div className="d-flex mb-4">
              <div className="form-floating w-50">
                <select
                  className="form-select"
                  id="gender"
                  name="gender"
                  aria-label="Floating label select example"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option selected>select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <label htmlFor="gender">Gender</label>
              </div>

              <form className="form-floating ms-3 w-50">
                <input
                  type="date"
                  name="date_of_birth"
                  className="form-control"
                  id="date_of_birth"
                  placeholder="date of birth"
                  value={values.date_of_birth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="date_of_birth">Date</label>
              </form>
            </div>
              <h6 style={{color:'red'}}>{message ? message :" "}</h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
           
            className="btn btn-danger"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Close
          </button>
          <button className="btn btn-danger" onClick={handleSubmit}>
            Search
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PatientModal;
