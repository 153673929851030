import React,{useState,useEffect} from "react";
import Card from "../../../../components/card/Card";
import '../PatientProfile.css'
import axios from "axios";
const Summary = ({id}) => {
  const [data,setData] = useState([])
  const [contacts,setContact] = useState([])
  const [medications,setMedications] = useState([])
  const [problems,setProblems] = useState([])
  const [patient,setPatients] = useState([])

  const getData = async()=>{
    try{
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const summary = await axios.get(`https://soulhousing-api.anchorstech.net/api/patient-summary/${id}`,config)
      setContact(summary.data.contacts)
      setMedications(summary.data.medications)
      setPatients(summary.data.patient)
      setProblems(summary.data.problems)
       
    }catch(error){
      console.log(error.message)
    }
  }
  useEffect(()=>{
    getData()
  },[])

  const contactItems = contacts.length > 0 ? contacts[0] : {};
  const medicationItems = medications.length > 0 ? medications[0] : {};
  const cardsData = [
      {
        title: "Basic Information",
        items: [
          { label: "Name: ", value: patient.first_name  , link: "#" },
          { label: "Gender", value: patient.gender , link: "#" },
          { label: "DOB:", value: patient.date_of_birth , link: "#" },
          { label: "Email: ", value: patient.email , link: "#" },
          { label: "Phone No: ", value: patient.phone_no , link: "#" },
        ]
      },
      {
        title: "Problems",
        items: problems.map((problem, index) => ({
      label: `Diagnosis ${index + 1}: `,
      value: problem.diagnosis,
      link: "#"
    }))
      },
      {
        title: "Allergies",
        items: [
          { label: "Allergy: ", value: "Dust", link: "#" },
          { label: "Allergen Type: ", value: "General Allergy", link: "#" },
          { label: "Allergy: ", value: "Aspirin", link: "#" },
          { label: "Allergen Type: ", value: "Substance Drugclass", link: "#" }
        ]
      },
      {
        title: "Contact Information",
        items: [
          { label: "Address: ", value: contactItems.address, link: "#" },
          { label: "Phone Numbers: ", value:contactItems.phone_number, link: "#" },
          { label: "Mobile: ", value: contactItems.mobile_phone, link: "#" },
          { label: "Home: ", value: contactItems.home_phone, link: "#" },
          { label: "Work: ", value: contactItems.work_phone, link: "#" },
          { label: "Email: ", value: contactItems.email, link: "#" },
          
         
        ]
      },
      {
        title: "Patient Alerts",
        items: [
          { value: "No alert found.", link: "#" },
          
        ]
      },
      {
        title: "Medications",
        items: [
          { label: "Prescriber: ", value: medicationItems.favourite_medication, link: "#" },
          { label: "Dispense: ", value:medicationItems.dispense, link: "#" },
          { label: "Date: ", value: medicationItems.prescribe_date, link: "#" },
          { label: "Refills: ", value: medicationItems.refills, link: "#" },
          { label: "Status: ", value: medicationItems.status, link: "#" },
          
        
        ]
      },
      {
        title: "Most Recent Encounter",
        items: [
          { label: "Date: ", value: "03/12/2024", link: "#" },
          { label: "Provider:", value: "Jon Smith (Neurological Surgery)", link: "#" },
          { label: "Location: ", value: "Dallas Clinic", link: "#" },
          { label: "Reason: ", value: "N/A", link: "#" },
          { label: "Type:", value: "Office Visit", link: "#" },
          { label: "Status:", link: "#" },
         
        ]
      },
      {
        title: "Messages",
        items: [
          { value: "No Messages", link: "#" },
          
        ]
      },
      // Add more card data as needed
    ];
  
    return (
     <div className="summary-container">
      <div className="d-flex flex-column h-100">
        <div className="problem-links">
          <h5>Demographics</h5>
        </div>
         <div className="active-bg-links flex-fill overflow-auto">
          <div className="row g-3">
            {cardsData.map((card, index) => (
              <div key={index} className="col-lg-3 col-xl-4">
                <Card title={card.title} items={card.items} />
              </div>
            ))}
          </div>
        </div>
      
       
        </div>
      </div>
    );
  };
  
  export default Summary;