import React, { useState } from 'react';
import './MyProfile.css'

function MyProfile() {
 
  return (
    <div className="right-sec flex-fill">
    <div className="tab-content h-100" id="v-pills-tabContent">
      {/* my profile tab start */}
      <div className="active fade h-100 overflow-auto show tab-pane" id="v-pills-myprofile" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <div className="myprofile-bg h-100 ">
          <h5 className="mb-3">My Profile</h5>
          <div className="row gx-2 mb-3">
            <div className="col-xl-3 col-lg-6">
              <div className="form-floating">
                <select className="form-select w-full " id="title">
                  <option selected="">select</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <label htmlFor="title">Title</label>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="form-floating mb-3">
                <input type="text" className="form-control" id="firstName" placeholder="First Name" />
                <label htmlFor="firstName">First Name</label>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="form-floating mb-3">
                <input type="text" className="form-control" id="lastName" placeholder="Last Name" />
                <label htmlFor="lastName">Last Name</label>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="form-floating mb-3">
                <input type="text" className="form-control" id="middleName" placeholder="Middle Name" />
                <label htmlFor="middleName">Middle Name</label>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Suffix</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="form-floating">
                      <select className="form-select w-100 " id="floatingSelectGrid">
                        <option selected="">select </option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <label for="floatingSelectGrid">Gender</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Email</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="date" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Date of Birth</label>
                    </div>
                  </div>
                  
                      <div className="col-xl-3 col-lg-6">
                        <div className="form-floating mb-3">
                          <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                          <label for="floatingInput">Email</label>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6">
                        <div className="form-floating">
                          <select className="form-select w-100 " id="floatingSelectGrid">
                            <option selected="">select </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label for="floatingSelectGrid">City</label>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6">
                        <div className="form-floating">
                          <select className="form-select w-100 " id="floatingSelectGrid">
                            <option selected="">select </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label for="floatingSelectGrid">City</label>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6">
                      <div className="form-floating mb-3">
                          <input type="email" className="form-control" id="floatingInput" placeholder="Zip Code"/>
                          <label for="floatingInput">Zip Code</label>
                        </div>
                        </div>
                      
                          <div className="col-xl-3 col-lg-6">
                            <div className="form-floating">
                              <select className="form-select w-100 " id="floatingSelectGrid">
                                <option selected="">select </option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                              <label for="floatingSelectGrid">Country</label>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6">
                            <div className="form-floating mb-3">
                              <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                              <label for="floatingInput">Home Phone</label>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6">
                            <div className="form-floating mb-3">
                              <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                              <label for="floatingInput">NPI</label>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6">
                            <div className="form-floating">
                              <select className="form-select w-100 " id="floatingSelectGrid">
                                <option selected="">select </option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                              <label for="floatingSelectGrid">TAX TYPE</label>
                            </div>
                          </div>
                           
                              <div className="col-xl-3 col-lg-6">
                                <div className="form-floating mb-3">
                                  <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                  <label for="floatingInput">SNN</label>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-6">
                                <div className="form-floating mb-3">
                                  <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                  <label for="floatingInput">EIN</label>
                                </div>
                              </div>
          </div>
          {/* electric forms */}
          <div className="electric-forms">
            <h5 className="mb-3">Electronic Prescribing</h5>
            <div className="row g-3 mb-3">
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating mb-1">
                  <input type="text" className="form-control" id="epcsStatus" placeholder="EPCS Status" />
                  <label htmlFor="epcsStatus">EPCS Status</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating mb-1">
                  <input type="text" className="form-control" id="deaNumber" placeholder="DEA Number" />
                  <label htmlFor="deaNumber">DEA Number</label>
                </div>
              </div>
              {/* More electronic prescribing form inputs */}
            </div>
          </div>
          {/* electric buttons */}
          <div className="electric-btn">
            <div className="d-flex mt-3">
              <button type="button" className="btn btn-electric">Cancel</button>
              <button type="button" className="btn btn-success ms-3">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default MyProfile;
