import React, { useState } from "react";
import Header from "../../../components/header/Header";
import MyProfile from "../setting/profile/MyProfile";
import Security from "../setting/security/Security";
import ChangePassword from "../setting/password/ChangePassword";
import ChangePin from "../setting/pin/ChangePin";
import ManageMap from "../setting/management/ManageMap";
import PatientProfile from "../profile/PatientProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./PatientDetail.css";
const PatientDetail = () => {
  const [activeTab, setActiveTab] = useState("myprofile"); // State to manage active tab

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="main-div">
      <Header />
      <div className="body-patient-detail container-fluid flex-fill overflow-hidden main-div ">
        <div className="d-flex flex-column h-100 overflow-hidden">
          <div className="patient-detail-tabs">
            <ul
              className="nav nav-tabs my-3 border-0"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link me-2 "
                  id="patient-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#MyPatients"
                  type="button"
                  role="tab"
                  aria-controls="Mypatient"
                  aria-selected="true"
                >
                  My Patients{" "}
                  <FontAwesomeIcon className="ml-2" icon={faTimes} />
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="jhonsmith-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#jhonsmith"
                  type="button"
                  role="tab"
                  aria-controls="jhonsmith"
                  aria-selected="false"
                >
                  Jon Smith <FontAwesomeIcon icon={faTimes} />
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link ms-2"
                  id="patient-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Setting-tab"
                  type="button"
                  role="tab"
                  aria-controls="jhonsmith"
                  aria-selected="false"
                >
                  Settings <FontAwesomeIcon icon={faTimes} />
                </button>
              </li>
            </ul>
            <hr></hr>
          </div>

          {/* MyPatients tab start */}
          <div
            className="flex-fill h-100 overflow-hidden tab-content"
            id="myTabContent"
          >
            <div
              className="tab-pane fade h-100"
              id="MyPatients"
              role="tabpanel"
              aria-labelledby="patient-tab"
            ></div>
            <div
              className="tab-pane fade h-100"
              id="jhonsmith"
              role="tabpanel"
              aria-labelledby="jhonsmith-tab"
            >
              <PatientProfile/>
            </div>

            {/* MyPatients tab Close */}
            {/* setting tab start */}

            <div
              className="tab-pane fade h-100"
              id="Setting-tab"
              role="tabpanel"
              aria-labelledby="patient-tab"
            >
              <div className="d-flex flex-column h-100">
                <div className="d-flex flex-fill h-100">
                  <div className="main-left">
                    <div className="left-nav ">
                      <div className="setting-bg">
                        <div className="d-flex align-items-start">
                          <div
                            className="nav flex-column nav-pills me-3 w-100"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <button
                              className={`nav-link ${
                                activeTab === "myprofile" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("myprofile")}
                              id="v-pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-myprofile"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected={activeTab === "myprofile"}
                            >
                              My Profile
                            </button>
                            <button
                              className={`nav-link ${
                                activeTab === "security" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("security")}
                              id="v-pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-Security"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-profile"
                              aria-selected={activeTab === "security"}
                            >
                              Security
                            </button>
                            <button
                              className={`nav-link ${
                                activeTab === "changepassword" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("changepassword")}
                              id="v-pills-messages-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-ChangePassword"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-messages"
                              aria-selected={activeTab === "changepassword"}
                            >
                              Change Password
                            </button>
                            <button
                              className={`nav-link ${
                                activeTab === "changepin" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("changepin")}
                              id="v-pills-settings-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-ChangePin"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-settings"
                              aria-selected={activeTab === "changepin"}
                            >
                              Change Pin
                            </button>
                            <button
                              className={`nav-link ${
                                activeTab === "faciltymanagement"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleTabClick("faciltymanagement")
                              }
                              id="v-pills-settings-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-FaciltyManagement"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-settings"
                              aria-selected={activeTab === "faciltymanagement"}
                            >
                              Facilty Management
                            </button>
                            <button
                              className={`nav-link ${
                                activeTab === "logout" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("logout")}
                              id="v-pills-settings-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-Logout"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-settings"
                              aria-selected={activeTab === "logout"}
                            >
                              Logout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Render MyProfile component if activeTab is 'myprofile' */}
                  {activeTab === "myprofile" && (
                    <div className="scroll-view">
                      <MyProfile />
                    </div>
                  )}

                  {/* Render other components for other tabs similarly */}
                  {activeTab === "security" && (
                    <div className="scroll-view">
                      <Security />
                    </div>
                  )}
                  {activeTab === "changepassword" && (
                    <div className="scroll-view">
                      <ChangePassword />
                    </div>
                  )}
                  {activeTab === "changepin" && (
                    <div className="scroll-view">
                      <ChangePin />
                    </div>
                  )}
                  {activeTab === "faciltymanagement" && (
                    <div className="scroll-view">
                      <ManageMap />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetail;
