import React,{useState} from 'react';
import './Header.css'
import Logo from '../../assets/images/logo (3).png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom'
import PatientModal from '../modal/AddPatientModal'
const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <header>
      <div className="header-sec-two d-flex align-items-center gap-2">
        <div className="brand-logo-patient">
          <img src={Logo} alt="Soul Housing Logo" />
        </div>

        <div className="input-group input-search rounded ms-5">
          <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
          <a href=""><FontAwesomeIcon icon={faSearch} className="search-icon" /></a>
        </div>

        <div className="facility-down">
          <div className="form-floating">
            <button type='button' className="btn btn-danger" onClick={handleShow}> Search Patient</button>
         
          </div>
        </div>

        {/* Button trigger modal */}
        <Link to="/registration_form" className="btn btn-danger">Add Patient</Link>

        {/* Your dropdown content */}
        <div className="d-flex align-items-center ms-auto">
          <div className="bell-icon">
          <FontAwesomeIcon icon={faBell} />
          </div>

          <div className="avatar dropdown">
            <div className="admin-name ms-4 dropdown-toggle no-arrow-dropdown" data-bs-toggle="dropdown">
              <a href="#">Jhon Smith</a>
              <a href="#"><FontAwesomeIcon icon={faAngleDown} className="drop-icon  ms-2" /></a>
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li><a className="dropdown-item active" href="#">Action</a></li>
              <li><a className="dropdown-item" href="#">Another action</a></li>
              <li><a className="dropdown-item" href="#">Something else here</a></li>
              <li><hr className="dropdown-divider"></hr></li>
              <li><a className="dropdown-item" href="#">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
         {/* Render the modal component */}
      <PatientModal show={show} handleClose={handleClose} handleShow={handleShow} registrationForm={<Link to ='/registration_form'/>}/>
    </header>
  );
};

export default Header;
